<template>
    <div class="row gy-3">
        <div class="col-12 col-md-3 mb-3 mb-md-0 d-flex justify-content-center align-items-center">
            <nuxt-link :to="$prismic.linkResolver(document)" class=" max-wpx-150">
                <PrismicImage
                    :img="image.img"
                    :alt="image.alt"
                    :w="image.width"
                    class="w-100 object-fit-contain"
                />
            </nuxt-link>
        </div>
        <div class="col-12 col-md-3 d-flex flex-md-column justify-content-center align-items-center gap-3 gap-md-0">
            <span>{{leftColumnHeader }}</span>
            <span class="fw-bold">{{ leftColumnValue }}</span>
        </div>
        <div class="col-12 col-md-3 d-flex flex-md-column justify-content-center align-items-center gap-3 gap-md-0">
            <span>{{ rightColumnHeader }}</span>
            <span class="fw-bold">{{ rightColumnValue }}</span>
        </div>
        <div class="col-12 col-md-3 d-flex align-items-center">
            <out-link
                class="btn btn-primary w-100" 
                :document="document" 
            />
        </div>
    </div>
</template>

<script>
export default {
    props: {
        document: {
            type: Object,
            required: true
        },
        image: {
            type: Object,
            required: true
        },
        leftColumnHeader: {
            type: String,
            required: true
        },
        leftColumnValue: {
            type: String,
            required: true
        },
        rightColumnHeader: {
            type: String,
            required: true
        },
        rightColumnValue: {
            type: String,
            required: true
        }
    },        
}
</script>